<template>
  <v-select
    v-model="location"
    :options="locations"
    :reduce="val => val.location_id"
    :loading="loading"
    :clearable="clearable"
    label="location_name"
    class="form-control p-0 border-control"
  />
</template>

<script>
import vSelect from 'vue-select'
import {
  computed, onMounted, onUnmounted, ref,
} from '@vue/composition-api'
import locationStoreService from '@/services/locationStoreService'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    vSelect,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    emitOnLoad: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    // Store
    const LOCATION_STORE_KEY = 'app-location'

    if (!store.hasModule(LOCATION_STORE_KEY)) store.registerModule(LOCATION_STORE_KEY, locationStoreService)

    onUnmounted(() => {
      if (store.hasModule(LOCATION_STORE_KEY)) store.unregisterModule(LOCATION_STORE_KEY)
    })

    // computed
    const location = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    // Variables
    const loading = ref(false)
    const locations = ref([])
    const toast = useToast()

    // Initial
    onMounted(() => {
      loading.value = true
      store.dispatch(`${LOCATION_STORE_KEY}/fetch`)
        .then(response => {
          locations.value = response.data

          if (!props.value && props.emitOnLoad) {
            emit('input', response.data[0].location_id)
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong fetching locations please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          loading.value = false
        })
    })

    return {
      location,
      loading,
      locations,
    }
  },
}
</script>

<style lang="scss" scoped>
.border-control::v-deep .vs__dropdown-toggle {
  border: 0;
  border-radius: 0;
}
</style>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
