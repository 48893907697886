<template>
  <b-modal
    :id="modalId"
    hide-header
    @hidden="$emit('close')"
  >
    <div class="d-flex justify-content-center my-4">
      <feather-icon
        class="icon-size"
        :class="iconClasses"
        :icon="icon"
      />
    </div>
    <slot />
    <template #modal-footer>
      <slot name="footer" />
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconClasses: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup() {
    const refId = Math.floor(Math.random() * 100000)

    const modalId = `modal-${refId}`

    return {
      modalId,
    }
  },
  methods: {
    open() {
      this.$bvModal.show(this.modalId)
    },
    close() {
      this.$bvModal.hide(this.modalId)
    },
  },
}
</script>

<style scoped>
.icon-size {
  height: 3rem;
  width: 3rem;
}
</style>
