<template>
  <div>
    <item-list-add-new
      ref="itemAdd"
      :is-add-new-item-sidebar-active.sync="isAddNewItemSidebarActive"
      @refetch-data="fetchItems"
    />

    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Location</label>
            <location-picker
              v-model="locationId"
              clearable
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="primary"
                @click="isAddNewItemSidebarActive = true"
              >
                <span class="text-nowrap">Add Item</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refItemsTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        :busy="loading"
      >
        <template v-slot:table-busy>
          <div class="d-flex justify-content-center my-4">
            <b-spinner
              type="grow"
              style="width: 3rem; height: 3rem;"
              variant="primary"
            />
          </div>
        </template>

        <template v-slot:cell(image)="data">
          <b-img-lazy
            v-if="data.item.item_image_url != null"
            :src="data.item.item_image_url"
            width="100"
            alt="item image"
          />
        </template>

        <template v-slot:cell(item_name)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="selectItemForEdit(data.item)"
          >
            {{ data.item.item_name }}
          </b-link>
        </template>

        <template v-slot:cell(price)="data">
          ${{ data.item.item_price }}
        </template>

        <template v-slot:cell(quantity)="data">
          {{ data.item.location_item_quantity != null ? data.item.location_item_quantity : 0 }}
        </template>
        <template v-slot:cell(actions)="data">
          <b-button
            variant="primary"
            size="sm"
            class="mr-0 mr-md-1"
            @click="updateQuantityForItem(data.item.item_id)"
          >
            Quantity
          </b-button>
          <b-button
            variant="secondary"
            size="sm"
            class="mr-0 mr-md-1"
            @click="refreshItemFromQB(data.item.item_id)"
          >
            Reimport
          </b-button>
          <b-button
            variant="outline-danger"
            size="sm"
            @click="confirmDelete(data.item.item_id)"
          >
            <span class="d-none d-lg-block">
              Delete
            </span>
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <icon-modal
      ref="confirm"
      icon="XOctagonIcon"
      icon-classes="text-danger"
      @close="itemId = null"
    >
      <p class="text-center">
        Are you sure you would like to delete this Item?
      </p>
      <template v-slot:footer>
        <b-button
          variant="default"
          @click="$refs.confirm.close()"
        >
          Go Back
        </b-button>
        <spinner-button
          variant="danger"
          :loading="deleting"
          @click="deleteItem"
        >
          Delete
        </spinner-button>
      </template>
    </icon-modal>
    <item-quantity-modal
      ref="quantity"
      :item-id="itemId"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BSpinner,
  BImgLazy,
  BLink,
} from 'bootstrap-vue'
import {
  ref, onUnmounted, computed, watch, nextTick,
} from '@vue/composition-api'
import store from '@/store'
import itemStoreService from '@/services/itemStoreService'
import vSelect from 'vue-select'
import ItemListAddNew from '@/views/ItemListAddNew.vue'
import IconModal from '@/layouts/components/IconModal.vue'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ItemQuantityModal from '@/views/ItemQuantityModal.vue'
import LocationPicker from '@/layouts/components/LocationPicker.vue'
import Vue from 'vue'

export default {
  components: {
    vSelect,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BSpinner,
    BImgLazy,
    BLink,
    ItemListAddNew,
    ItemQuantityModal,

    IconModal,
    SpinnerButton,
    LocationPicker,
  },
  setup() {
    // Store setup
    const ITEM_STORE_MODULE_NAME = 'app-item'
    if (!store.hasModule(ITEM_STORE_MODULE_NAME)) store.registerModule(ITEM_STORE_MODULE_NAME, itemStoreService)

    onUnmounted(() => {
      if (store.hasModule(ITEM_STORE_MODULE_NAME)) store.unregisterModule(ITEM_STORE_MODULE_NAME)
    })

    // Variables
    const itemAdd = ref(null)
    const confirm = ref(null)
    const refItemsTable = ref(null)
    const items = ref([])
    const loading = ref(false)
    const updating = ref(false)
    const deleting = ref(false)
    const locationId = ref(null)
    const perPage = ref(25)
    const totalItems = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const quantity = ref(null)

    const itemId = ref(null)

    const isAddNewItemSidebarActive = ref(false)

    const toast = useToast()

    // Computed
    const dataMeta = computed(() => {
      const localItemsCount = refItemsTable.value
        ? refItemsTable.value.localItems.length
        : 0
      return {
        from:
            perPage.value * (currentPage.value - 1)
            + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalItems.value,
      }
    })
    const tableColumns = computed(() => {
      if (locationId.value != null) {
        return [
          {
            key: 'image',
            label: '',
          },
          {
            key: 'item_name',
            label: 'Name',
          },
          {
            key: 'item_display_name',
            label: 'Display Name',
          },
          {
            key: 'item_details',
            label: 'Description',
          },
          {
            key: 'price',
          },
          {
            key: 'quantity',
          },
          {
            key: 'actions',
          },
        ]
      }
      return [
        {
          key: 'image',
          label: '',
        },
        {
          key: 'item_name',
          label: 'Name',
        },
        {
          key: 'item_display_name',
          label: 'Display Name',
        },
        {
          key: 'item_details',
          label: 'Description',
        },
        {
          key: 'price',
        },
        {
          key: 'actions',
        },
      ]
    })

    // Actions
    const fetchItems = () => {
      loading.value = true
      store.dispatch('app-item/fetchItems', {
        page: currentPage.value,
        pageSize: perPage.value,
        locationId: locationId.value,
      })
        .then(response => {
          items.value = response.data.data
          totalItems.value = response.data.total
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          loading.value = false
        })
    }

    const deleteItem = () => {
      deleting.value = true
      store.dispatch('app-item/deleteItem', {
        id: itemId.value,
      }).then(() => {
        fetchItems()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Successfully deleted item',
            icon: 'CheckSquareIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error deleting item. Please try again later',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        deleting.value = false
        fetchItems()
        confirm.value.close()
      })
    }

    const updateEdit = (index, value) => {
      const clone = items.value
      clone[index].edit = value
      items.value = clone
    }

    const selectItemForEdit = item => {
      itemAdd.value.setExistingItem(JSON.parse(JSON.stringify(item)))

      nextTick(() => {
        isAddNewItemSidebarActive.value = true
      })
    }

    const updateQuantityForItem = selected => {
      itemId.value = selected
      if (locationId.value != null) {
        quantity.value.setLocationId(locationId.value)
      }

      quantity.value.open()
    }

    const refreshItemFromQB = selectedItemId => {
      store.dispatch('app-item/reimportItem', {
        id: selectedItemId,
      })
        .then(response => {
          const existingItemIndex = items.value.findIndex(item => item.item_id === selectedItemId)
          console.log(existingItemIndex)
          if (existingItemIndex >= 0) {
            Vue.set(items.value, existingItemIndex, response.data.item)
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error reimporting item. Please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    // Watch
    watch([
      perPage,
      currentPage,
      locationId,
    ], () => {
      fetchItems()
    })

    fetchItems()

    return {
      itemAdd,
      items,
      itemId,
      loading,
      updating,
      deleting,
      tableColumns,
      perPage,
      perPageOptions,
      totalItems,
      currentPage,
      dataMeta,
      refItemsTable,
      confirm,
      locationId,

      quantity,
      updateEdit,
      selectItemForEdit,
      updateQuantityForItem,
      refreshItemFromQB,

      isAddNewItemSidebarActive,

      fetchItems,
      deleteItem,
    }
  },
  methods: {
    confirmDelete(id) {
      this.itemId = id
      this.$refs.confirm.open()
    },
    goBack() {
      this.$refs.confirm.close()
    },
    toggleDetails(data) {
      const clone = JSON.parse(JSON.stringify(this.items))

      clone.forEach((i, index) => {
        clone[index].editing = false
      })
      if (clone[data.index].editing) {
        clone[data.index].editing = !clone[data.index].editing
      } else {
        clone[data.index].editing = true
      }

      this.items = clone
      this.resetUpdate()
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
