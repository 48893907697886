<template>
  <v-select
    v-model="category"
    placeholder="Select a category"
    :loading="loading"
    :options="categories"
    label="category_name"
    :reduce="val => val.category_id"
    class="form-control p-0 border-control"
    :multiple="multiple"
  />
</template>

<script>
import vSelect from 'vue-select'
import { ref, computed, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import itemStoreService from '@/services/itemStoreService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    vSelect,
  },
  props: {
    value: {
      type: [Number, String, Array],
      required: false,
      default: null,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    // Store
    const ITEM_STORE_KEY = 'app-item'
    if (!store.hasModule(ITEM_STORE_KEY)) store.registerModule(ITEM_STORE_KEY, itemStoreService)
    onUnmounted(() => {
      if (store.hasModule(ITEM_STORE_KEY)) store.unregisterModule(ITEM_STORE_KEY)
    })

    // Constants
    const toast = useToast()

    // Variables
    const loading = ref(false)
    const categories = ref([])

    // Computed
    const category = computed({
      get() {
        if (props.multiple) {
          return categories.value.filter(c => props.value.includes(c.category_id))
        }
        return categories.value.find(c => +c.category_id === +props.value)
      },
      set(selected) {
        emit('input', selected)
      },
    })

    // Functions
    const fetch = () => {
      loading.value = false
      store.dispatch(`${ITEM_STORE_KEY}/fetchCategories`)
        .then(response => {
          categories.value = response.data
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong fetching the categories please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          loading.value = false
        })
    }

    // Initial
    fetch()

    return {
      loading,
      categories,
      category,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/vue/libs/vue-select.scss";

.border-control::v-deep .vs__dropdown-toggle {
  border: 0;
  border-radius: 0;
}
</style>
