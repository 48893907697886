import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Items
    fetchItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/items', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addItem(ctx, itemData) {
      const headers = {
        'Content-Type': 'multipart/form-data',
      }
      const formData = new FormData()
      formData.append('name', itemData.item_name)
      formData.append('display_name', itemData.item_display_name)
      formData.append('details', itemData.item_details)
      formData.append('price', itemData.item_price)
      formData.append('palette_order', itemData.item_palette_order)
      formData.append('is_taxable', JSON.stringify(+itemData.item_is_taxable === 1))
      formData.append('category_id', itemData.category_id)
      if (itemData.item_image != null) {
        formData.append('item_image', itemData.item_image)
      }

      return new Promise((resolve, reject) => {
        axios.post('/items', formData, headers)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateItem(ctx, { itemData }) {
      const headers = {
        'Content-Type': 'multipart/form-data',
      }
      const formData = new FormData()
      formData.append('name', itemData.item_name)
      formData.append('display_name', itemData.item_display_name)
      formData.append('details', itemData.item_details)
      formData.append('price', itemData.item_price)
      formData.append('palette_order', itemData.item_palette_order)
      formData.append('minimum_quantity_threshold', itemData.item_minimum_quantity_threshold)
      formData.append('is_taxable', JSON.stringify(+itemData.item_is_taxable === 1))
      formData.append('category_id', itemData.category_id)
      if (itemData.item_image != null) {
        formData.append('item_image', itemData.item_image)
      }

      return new Promise((resolve, reject) => {
        axios.post(`/items/${itemData.item_id}`, formData, headers)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    reimportItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios.post(`items/${id}/reimport`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`items/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchImportItems() {
      return new Promise((resolve, reject) => {
        axios.get('/items/import-options')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    importItem(ctx, { itemData, isTaxable, categoryId }) {
      return new Promise((resolve, reject) => {
        axios.post('/items/import', {
          importData: itemData.data,
          isTaxable,
          category_id: categoryId,
        }).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateQuantity(ctx, { id, data }) {
      const update = {
        location_id: data.locationId,
      }
      update[data.type] = data.quantity
      return new Promise((resolve, reject) => {
        axios.put(`/items/${id}/quantity`, update)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItemsLite() {
      return new Promise((resolve, reject) => {
        axios.get('/items?lite=true')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Item Groups
    fetchItemGroups(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/item-groups', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateItemGroup(ctx, { itemGroupId, itemGroupData }) {
      const headers = {
        'Content-Type': 'multipart/form-data',
      }
      const formData = new FormData()
      formData.append('name', itemGroupData.name)
      formData.append('category_id', itemGroupData.category_id)
      formData.append('display_name', itemGroupData.display_name)
      formData.append('details', itemGroupData.details)
      formData.append('price', itemGroupData.price)
      if (itemGroupData.item_image != null) {
        formData.append('group_image', itemGroupData.item_image)
      }

      return new Promise((resolve, reject) => {
        axios.post(`/item-groups/${itemGroupId}`, formData, headers)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    reimportItemGroup(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios.post(`item-groups/${id}/reimport`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteItemGroup(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/item-groups/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItemGroupAssns(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios.get(`/item-groups/${id}/items`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveItemGroupAssns(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios.put(`/item-groups/${id}/items`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItemMaterials() {
      return new Promise((resolve, reject) => {
        axios.get('/orders/builder-options')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategories() {
      return new Promise((resolve, reject) => {
        axios.get('/categories')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createCategory(ctx, { formData }) {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      }
      return new Promise((resolve, reject) => {
        axios.post('/categories', formData, headers)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCategory(ctx, { id, formData }) {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      }
      return new Promise((resolve, reject) => {
        axios.post(`/categories/${id}`, formData, headers)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCategory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/categories/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios.get(`/categories/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrderOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.get('/items/order-options', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchImportItemGroups() {
      return new Promise((resolve, reject) => {
        axios.get('/item-groups/import-options')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    importItemGroup(ctx, { importData, categoryId }) {
      return new Promise((resolve, reject) => {
        axios.post('/item-groups/import', {
          importData: importData.data,
          category_id: categoryId,
          isTaxable: true,
        }).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
