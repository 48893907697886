<template>
  <b-sidebar
    id="add-new-item-sidebar"
    :visible="isAddNewItemSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @shown="fetchItems"
    @hidden="resetItemData(), resetForm()"
    @change="(val) => $emit('update:is-add-new-item-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ +itemData.item_id === 0 ? 'Add New' : 'Update' }} Item
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          ref="refFormObserver"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Item Form -->
          <template v-if="+itemData.item_id > 0">

            <!-- Item Name -->
            <validation-provider
              #default="validationContext"
              name="Item Name"
              rules="required"
            >
              <b-form-group
                label="Item Name"
                label-for="item-name"
              >
                <b-form-input
                  id="item-name"
                  v-model="itemData.item_name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Item Display Name -->
            <validation-provider
              #default="validationContext"
              name="Item Display Name"
              rules="required"
            >
              <b-form-group
                label="Item Display Name"
                label-for="display-name"
              >
                <b-form-input
                  id="display-name"
                  v-model="itemData.item_display_name"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>
            </validation-provider>

            <!-- Item Detail -->
            <validation-provider
              #default="validationContext"
              name="Item Detail"
              rules="max:255"
            >
              <b-form-group
                label="Item Detail"
                label-for="item-detail"
              >
                <b-form-textarea
                  id="item-detail"
                  v-model="itemData.item_details"
                  :state="getValidationState(validationContext)"
                  trim
                  size="sm"
                />
                <template v-slot:description>
                  {{ itemData.item_details.length }} / 255
                </template>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Item Price -->
            <validation-provider
              #default="validationContext"
              name="Item Price"
              rules="required"
            >
              <b-form-group
                label="Item Price"
                label-for="item-price"
              >

                <b-input-group
                  id="item-price"
                >
                  <b-input-group-text slot="prepend">
                    $
                  </b-input-group-text>
                  <cleave
                    v-model="itemData.item_price"
                    class="form-control"
                    :class="getValidationStateNonBootstrap(validationContext)"
                    :options="priceOptions"
                  />
                </b-input-group>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Item Category -->
            <b-form-group label="Item Category">
              <category-picker
                v-model="itemData.category_id"
              />
            </b-form-group>

            <!-- Item Palette Order -->
            <validation-provider
              #default="validationContext"
              name="Item Pallet Order"
              rules="required|min_value:1"
            >
              <b-form-group
                label="Item Pallet Order"
                label-for="item-palette-order"
              >

                <cleave
                  id="item-palette-order"
                  v-model="itemData.item_palette_order"
                  class="form-control"
                  :class="getValidationStateNonBootstrap(validationContext)"
                  :options="quantityOptions"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Item Minimum Quantity Threshold -->
            <validation-provider
              #default="validationContext"
              name="Item Minimum Quantity Threshold"
              rules="required"
            >
              <b-form-group
                label="Item Minimum Quantity Threshold"
                label-for="item-minimum-quantity-order"
              >

                <b-form-input
                  id="item-minimum-quantity-order"
                  v-model="itemData.item_minimum_quantity_threshold"
                  type="number"
                  class="form-control"
                  :class="getValidationStateNonBootstrap(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Item Image -->
            <validation-provider
              #default="validationContext"
              name="Item Image"
            >

              <b-form-group
                label="Item Image"
                label-for="image"
              >
                <b-form-file
                  id="image"
                  v-model="itemData.item_image"
                  accept=".jpg,.jpeg,.png,.svg"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!--  Is this item taxable -->
            <validation-provider
              name="taxable"
              :rules="{ required: false }"
            >
              <b-form-group>
                <b-form-checkbox
                  :checked="+itemData.item_is_taxable === 0"
                  @input="val => itemData.item_is_taxable = (val ? 0 : 1)"
                >
                  Tax exempt?
                </b-form-checkbox>
              </b-form-group>
            </validation-provider>
          </template>

          <template v-else>
            <validation-provider
              #default="validationContext"
              name="Item Import"
              rules="required"
            >
              <b-form-group label="Import Item">
                <v-select
                  v-model="importData"
                  placeholder="Select an item"
                  :loading="loading"
                  :options="items"
                  label="name"
                  class="form-control p-0 border-control"
                  :class="[getValidationStateNonBootstrap(validationContext)]"
                >
                  <template #option="data">
                    <p class="mb-0">
                      {{ data.name }} (${{ data.unit_price.toLocaleString('en-US', formatOptions) }})
                    </p>
                    <p class="text-overflow mb-0">
                      {{ data.description }}
                    </p>
                  </template>
                </v-select>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Item Category -->
            <b-form-group label="Item Category">
              <category-picker
                v-model="itemData.category_id"
              />
            </b-form-group>

            <validation-provider
              name="taxable"
              :rules="{ required: false }"
            >
              <b-form-group>
                <b-form-checkbox
                  :checked="+itemData.item_is_taxable === 0"
                  @input="val => itemData.item_is_taxable = (val ? 0 : 1)"
                >
                  Tax exempt?
                </b-form-checkbox>
              </b-form-group>
            </validation-provider>
          </template>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <spinner-button
              :loading="saving"
              variant="primary"
              class="mr-2"
              type="submit"
            >{{ actionButtonTitle }}</spinner-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupText,
  BFormFile,
  BFormCheckbox,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, computed } from '@vue/composition-api'
import {
  required, alphaNum, email, is, password, size, max, minValue,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CategoryPicker from '@/views/categories/CategoryPicker.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupText,
    BFormFile,
    BFormCheckbox,
    vSelect,
    SpinnerButton,
    Cleave,
    CategoryPicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewItemSidebarActive',
    event: 'update:is-add-new-item-sidebar-active',
  },
  props: {
    isAddNewItemSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      is,
      password,
      size,
      max,
      minValue,
      priceOptions: {
        numeral: true,
        numeralPositiveOnly: true,
      },
      quantityOptions: {
        numeral: true,
        numeralPositiveOnly: true,
        numeralDecimalScale: 0,
      },
      localeOptions: [
        undefined,
        'en-US',
      ],
      formatOptions: {
        minimumFractionDigits: 2,
      },
    }
  },
  setup(props, { emit }) {
    const toast = useToast()

    const loading = ref(false)
    const saving = ref(false)
    const isImporting = ref(true)

    const items = ref([])

    const blankItem = {
      item_id: 0,
      item_name: '',
      item_display_name: '',
      item_details: '',
      item_price: null,
      item_palette_order: null,
      item_image: null,
      item_is_taxable: 1,
      category_id: null,
    }

    const itemData = ref(JSON.parse((JSON.stringify(blankItem))))
    const importData = ref(null)
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankItem))
      importData.value = null
    }

    const finish = () => {
      emit('refetch-data')
      emit('update:is-add-new-item-sidebar-active', false)
      saving.value = false
    }

    const showErrorDialog = (error, updating = false) => {
      if (error.response && error.response.data && error.response.data.error) {
        Object.keys(error.response.data.error.data).forEach(errorKey => {
          error.response.data.error.data[errorKey].forEach(e => {
            toast({
              component: ToastificationContent,
              props: {
                title: e,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        })
      } else {
        let message = 'Error adding item please try again later'
        if (updating) {
          message = 'Error updating this item please try again later'
        }
        toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }

    const updateItem = () => {
      store.dispatch('app-item/updateItem', { itemData: itemData.value })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully updated this item',
              variant: 'success',
              icon: 'CircleCheckIcon',
            },
          })
          finish()
        }).catch(error => {
          showErrorDialog(error, true)
          saving.value = false
        })
    }

    const onSubmit = () => {
      saving.value = true
      if (+itemData.value.item_id > 0) {
        updateItem()
      } else {
        store.dispatch('app-item/importItem', { itemData: importData.value, isTaxable: itemData.value.item_is_taxable, categoryId: itemData.value.category_id }).then(() => {
          finish()
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding item please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          saving.value = false
        })
      }
    }

    const fetchItems = () => {
      if (itemData.value.item_id === 0) {
        loading.value = true
        store.dispatch('app-item/fetchImportItems').then(response => {
          items.value = response.data
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching items please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          loading.value = false
        })
      }
    }

    const {
      refFormObserver, getValidationState, getValidationStateNonBootstrap, resetForm,
    } = formValidation(resetItemData)

    const setExistingItem = item => {
      itemData.value = item
    }

    const actionButtonTitle = computed(() => {
      if (itemData.value.item_id > 0) {
        return 'Update'
      }

      return 'Import'
    })

    return {
      itemData,
      importData,
      onSubmit,
      fetchItems,
      loading,
      saving,
      items,
      actionButtonTitle,

      refFormObserver,
      getValidationState,
      getValidationStateNonBootstrap,
      resetForm,
      setExistingItem,
      resetItemData,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/vue/libs/vue-select.scss";

#add-new-item-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.text-overflow {
  text-overflow: ellipsis;
}

.border-control::v-deep .vs__dropdown-toggle {
  border: 0;
  border-radius: 0;
}
</style>
