<template>
  <b-modal
    v-model="show"
    hide-header
    @hidden="reset()"
  >
    <validation-observer
      #default="{ handleSubmit }"
    >
      <b-form
        id="quantity-update"
        ref="refFormObserver"
        @submit.prevent="handleSubmit(onSubmit)"
      >

        <validation-provider
          #default="validationContext"
          name="location"
          rules="required"
        >
          <b-form-group label="Location">
            <location-picker
              v-model="update.locationId"
              :class="getValidationStateNonBootstrap(validationContext)"
              @input="fetchQuantity"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="quantity"
          :rules="quantityRules"
        >
          <b-form-group label="Quantity">
            <b-form-input
              v-model="update.quantity"
              :state="getValidationState(validationContext)"
              number
              type="number"
              min="1"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>

            <template
              v-slot:description
            >
              <template v-if="currentQuantity != null">
                Current quantity on location: {{ currentQuantity }}
              </template>
              <template v-else-if="fetching">
                <b-spinner small />
              </template>
            </template>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="type"
          rules="required"
        >
          <b-form-group>

            <b-form-radio-group
              v-model="update.type"
              :options="options"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
    <template v-slot:modal-footer>
      <b-button
        @click="close()"
      >
        Go Back
      </b-button>
      <spinner-button
        :loading="saving"
        form="quantity-update"
        type="submit"
        variant="primary"
      >
        Save
      </spinner-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormInput,
  BFormRadioGroup,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import LocationPicker from '@/layouts/components/LocationPicker.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import store from '@/store'
import itemStoreService from '@/services/itemStoreService'
import locationStoreService from '@/services/locationStoreService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { required, minValue, maxValue } from '@core/utils/validations/validations'

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    BFormRadioGroup,
    BButton,
    BSpinner,
    LocationPicker,
    SpinnerButton,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    itemId: {
      type: [Number, String],
      default: null,
    },
  },
  data: () => ({
    required,
    maxValue,
    minValue,
  }),
  setup(props) {
    // Store
    const ITEM_MODULE_KEY = 'app-item'
    const LOCATION_MODULE_KEY = 'app-location'
    if (!store.hasModule(ITEM_MODULE_KEY)) store.registerModule(ITEM_MODULE_KEY, itemStoreService)
    if (!store.hasModule(LOCATION_MODULE_KEY)) store.registerModule(LOCATION_MODULE_KEY, locationStoreService)

    onUnmounted(() => {
      if (store.hasModule(ITEM_MODULE_KEY)) store.unregisterModule(ITEM_MODULE_KEY)
      if (store.hasModule(LOCATION_MODULE_KEY)) store.unregisterModule(LOCATION_MODULE_KEY)
    })

    // Constants
    const TYPE_ADD = 'add'
    const TYPE_REMOVE = 'remove'
    const toast = useToast()

    // Variables
    const show = ref(false)
    const blankUpdate = {
      locationId: null,
      quantity: null,
      type: TYPE_ADD,
    }
    const update = ref(JSON.parse(JSON.stringify(blankUpdate)))
    const options = [
      {
        text: 'Add',
        value: TYPE_ADD,
      },
      {
        text: 'Remove',
        value: TYPE_REMOVE,
      },
    ]
    const saving = ref(false)
    const fetching = ref(false)
    const currentQuantity = ref(null)

    // Functions
    const open = () => {
      show.value = true
    }

    const close = () => {
      show.value = false
    }

    const reset = () => {
      update.value = JSON.parse(JSON.stringify(blankUpdate))
    }

    const onSubmit = () => {
      saving.value = true
      store.dispatch(`${ITEM_MODULE_KEY}/updateQuantity`, { id: props.itemId, data: update.value })
        .then(response => {
          const quantity = response?.data?.quantity
          let message = ''
          if (quantity) {
            message = `Current quantity: ${quantity}`
          }
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully updated the items quantity',
              text: message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          close()
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong updating this items quantity please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          saving.value = false
        })
    }

    const fetchQuantity = locationId => {
      currentQuantity.value = null
      fetching.value = true
      store.dispatch(`${LOCATION_MODULE_KEY}/getQuantity`, { itemId: props.itemId, locationId })
        .then(response => {
          currentQuantity.value = response.data.quantity
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong retrieving current quantity please try again later',
              variant: 'danger',
              icon: 'AlertTriangleIcon',
            },
          })
        }).finally(() => {
          fetching.value = false
        })
    }
    const setLocationId = locationId => {
      update.value.locationId = locationId
    }

    const {
      refFormObserver, getValidationState, getValidationStateNonBootstrap,
    } = formValidation(reset)

    const quantityRules = computed(() => {
      const base = {
        required: true,
        min_value: 1,
      }

      if (update.value.type === TYPE_REMOVE && currentQuantity.value != null) {
        base.max_value = currentQuantity.value
      }

      return base
    })

    return {
      show,
      update,
      saving,

      options,

      refFormObserver,
      getValidationStateNonBootstrap,
      getValidationState,
      fetching,
      fetchQuantity,
      currentQuantity,

      quantityRules,

      open,
      close,
      reset,
      onSubmit,
      setLocationId,
    }
  },
}
</script>
