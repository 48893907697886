import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetch() {
      return new Promise((resolve, reject) => {
        axios.get('/locations')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLocation(ctx, { locationId }) {
      return new Promise((resolve, reject) => {
        axios.get(`/locations/${locationId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMinMaxDates(ctx, { locationId }) {
      return new Promise((resolve, reject) => {
        axios.get(`/locations/${locationId}/order-date-range`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDateAvailability(ctx, { locationId, date }) {
      return new Promise((resolve, reject) => {
        axios.get(`/locations/${locationId}/availability?date=${date}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchHours(ctx, { locationId }) {
      return new Promise((resolve, reject) => {
        axios.get(`/locations/${locationId}/hours`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClosures(ctx, { locationId }) {
      return new Promise((resolve, reject) => {
        axios.get(`/locations/${locationId}/closures`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteHours(ctx, { locationId, locationHoursId }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/locations/${locationId}/hours/${locationHoursId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteClosure(ctx, { locationId, locationClosureId }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/locations/${locationId}/closures/${locationClosureId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addHours(ctx, { locationId, params }) {
      return new Promise((resolve, reject) => {
        axios.post(`/locations/${locationId}/hours`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addClosure(ctx, { locationId, date }) {
      return new Promise((resolve, reject) => {
        axios.post(`/locations/${locationId}/closures`, { date })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchQuotaOverrides(ctx, { locationId }) {
      return new Promise((resolve, reject) => {
        axios.get(`/locations/${locationId}/daily-order-quotas`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteOverride(ctx, { locationId, overrideId }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/locations/${locationId}/daily-order-quotas/${overrideId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addOverride(ctx, { locationId, date, max_orders }) {
      return new Promise((resolve, reject) => {
        axios.post(`/locations/${locationId}/daily-order-quotas`, { date, max_orders })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getQuantity(ctx, { itemId, locationId }) {
      return new Promise((resolve, reject) => {
        axios.get(`/locations/${locationId}/items/${itemId}/quantity`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateDailyOrderQuota(ctx, { locationId, limit }) {
      return new Promise((resolve, reject) => {
        axios.put(`/locations/${locationId}/daily-order-limit`, { limit })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCancellationWindow(ctx, { locationId, hours }) {
      return new Promise((resolve, reject) => {
        axios.put(`/locations/${locationId}/cancellation-lead-time`, { hours })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateOrderLeadTime(ctx, { locationId, type, value }) {
      return new Promise((resolve, reject) => {
        axios.put(`/locations/${locationId}/placement-lead-time`, { type, value })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateLocationBuilderDisclaimer(ctx, { locationId, type, value }) {
      return new Promise((resolve, reject) => {
        axios.put(`/locations/${locationId}/builder-disclaimer`, { type, value })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    itemInventoryReport(ctx, { locationId, enforceThreshold }) {
      return new Promise((resolve, reject) => {
        axios.get(`/locations/${locationId}/reports/inventory?enforceThreshold=${enforceThreshold ? 'true' : 'false'}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
